<template>
  <div>
    <modal
      name="letterDNCModal"
      :width="250"
      height="auto"
      :draggable="true"
      :focus-trap="true"
      @before-open="beforeOpen"
      @before-close="beforeClose"
    >
      <div id="modal-container">
        <div id="modalTitle">Letter DNC</div>
        <div v-for="(item, index) in add.letterDNC" :key="item.index">
          <input v-model="add.letterDNC[index]" type="text" class='listItem' />
          <span class='delButton' v-on:click='deleteItem(index)' ><img src="https://img.icons8.com/ios/18/000000/minus.png"/></span>
        </div>
        <button v-on:click="addEmpty" class='modal-button'>Add Additional</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { createLog } from "./../../../functions/supporting";
export default {
  name: "letterDNCModal",
  data() {
    return {
      add: {},
      editing: '',
    };
  },
  methods: {
    beforeOpen(event) {
      this.$data.add = event.params.add;
      this.addToEditing(event.params.add);
    },
    beforeClose() {
      for (const eleme of this.$data.add.letterDNC) {
        if (eleme === "") {
          var index = this.$data.add.letterDNC.indexOf(eleme);
          this.$data.add.letterDNC.splice(index, 1);
        }
      }
      //this.editqueue(this.$data.add);
      var tempStatus = Object.assign({},this.$data.editing)
      this.$data.add.updateAddr(tempStatus,'letterDNC')
    },
    addEmpty() {
      var count = 0;
      for (const ele of this.$data.add.letterDNC) {
        if (ele === "") {
          count++;
        }
      }
      if (count === 0) {
        this.$data.add.letterDNC.push("");
      }
    },
    addToEditing(add) {
      this.$data.editing = JSON.parse(JSON.stringify(add));
    },
    editqueue(needsEdit) {
      var pastAddr = JSON.parse(JSON.stringify(this.$data.editing));
      var newAddr = JSON.parse(JSON.stringify(needsEdit));
      if (!this.lodash.isEqual(newAddr, pastAddr)) {
        for (const prop in newAddr) {
          if (!this.lodash.isEqual(newAddr[prop], pastAddr[prop])) {
            console.log('LDNC')
            var log = createLog(newAddr[prop], pastAddr[prop], "edit ", prop);
            needsEdit.addressLogs.push(log);
          }
        }
        this.$store.commit("appendEdits", needsEdit);
      }
    },
    deleteItem(index){
      this.$data.add.letterDNC.splice(index,1)
    }
  },
};
</script>
