<template>
  <div>
    <div id="body-container">
      <div id="terheader">
        <div id="territoryName">{{ territory.terInfo.territoryName }}</div>
        <div id="terHeaderOptions">
          <div id="slideOutBtn" v-on:click='toggleSlide' v-if='!slideout'><img src="../../assets/PushLeft.svg" class='pushLSymb'></div>
          <div id="slideOutBtn" v-on:click='toggleSlide' v-else><img src="../../assets/PushRight.svg" class='pushRSymb'></div>
          <span id="terEditButton">edit</span>
        </div>
      </div>
      <div id="body" :key="bodyKey">
        <div
          v-for="street in territory.data.streets"
          :key="street.uid"
          class="streetBlock"
        >
          <div class="streetName">
            {{ street.streetName }}
            <span class='streetEditButton writeSymbSpan'><img src="../../assets/Write.svg" class='writeSymb'></span>
            <span class='plusSymbSpan' v-on:click='addChildAddr(territory,street)'><img src="../../assets/AddButton.svg" class='plusSymb'></span>
          </div>
          <div class="streetBlockKey" >
            <div class="streetKey">no.</div>
            <div class="streetKey">Phone</div>
            <div class="streetKey">Letter</div>
            <div class="streetKey">Door Status</div>
            <div class="streetKey">Letter DNC</div>
            <div class="streetKey">Phone DNC</div>
          </div>
          <div
            v-for="add in street.addresses"
            :key="add.addressID"
            class="addrBlock"
          >
            <div class="houseNumber altAddr" v-on:click="addToEditing(add)" v-bind:class='{notValid:!valididateHN(add.houseNumber)}' >
              <input v-model="add.houseNumber" v-on:change="add.updateAddr(editingAddress,'houseNumber')" />
            </div>
            <div class="phoneNumber addr" v-on:click="showPhoneModal(add)" >
              {{
                add.phoneNumbers.length > 0
                  ? add.phoneNumbers.length + " Numbers"
                  : "none"
              }}
            </div>
            <div class="letter addr" v-on:click="showLetterModal(add)">
              {{
                add.resNames.length > 1
                  ? add.resNames.length + " Names"
                  : add.resNames.length === 1 && add.resNames[0] != ""
                  ? truncateSTR(add.resNames[0],10)
                  : "none"
              }}
            </div>
            <div class="doorStatus addr" v-on:click="showDoorStatusModal(add)">
              {{
                doorStatusDisplay(add.doorStatus)
              }}
            </div>
            <div class="letterDNC addr" v-on:click="showLetterDNCModal(add)">
              {{
                add.letterDNC.length > 1
                  ? add.letterDNC.length + " Names"
                  : add.letterDNC.length === 1 && add.letterDNC[0] != ""
                  ? truncateSTR(add.letterDNC[0],10)
                  : "none"
              }}
            </div>
            <div class="phoneDNC addr" v-on:click="showPhoneDNCModal(add)">
              {{
                add.phoneDNC.length > 0
                  ? add.phoneDNC.length + " Numbers"
                  : "none"
              }}
            </div>
            <div>
            <span class='addr etc paperFoldedSymbSpan' v-on:click="showNotesModal(add)"><img src="../../assets/PaperFolded.svg" class='paperFoldedSymb'></span>
            <span class="etc addr gridSymbSpan" v-on:click="showInfoModal(add)"><img src="../../assets/Grid.svg" class='gridSymb'></span>
            <span class="etc addr gridSymbSpan" v-on:click="deleteAddr(add)"><img src="../../assets/Cross.svg" class='gridSymb'></span>
            </div>      
          </div>
        </div>
        <div id="addStreet" v-on:click="addStreet(territory)"><img src="../../assets/AddButton.svg" class='plusSymb'>add new street</div>
      </div>
    </div>
    <DoorStatusModal />
    <LetterModal />
    <LetterDNCModal />
    <PhoneModal />
    <PhoneDNCModal />
    <NotesModal />
    <InfoModal />
  </div>
</template>

<script>
import DoorStatusModal from "./modals/doorStatusModal";
import PhoneModal from "./modals/phoneModal";
import PhoneDNCModal from "./modals/phoneDNCModal";
import LetterModal from "./modals/letterModal";
import LetterDNCModal from "./modals/letterDNCModal";
import NotesModal from "./modals/notesModal";
import InfoModal from "./modals/infoModal";
import { truncate } from "./../../functions/supporting";

export default {
  data() {
    return {
      editingAddress: {},
      slideout: false,
      bodyKey: 0,
    };
  },
  components: {
    DoorStatusModal,
    PhoneModal,
    PhoneDNCModal,
    LetterModal,
    LetterDNCModal,
    NotesModal,
    InfoModal
  },
  computed: {
    territory() {
      return this.$store.getters.editTerritory;
    },
  },
  methods: {
    toggleSlide(){
      if(this.$data.slideout == false){
        document.getElementById('leftCol').style.display = 'none';
        this.$data.slideout = true
      } else {
        document.getElementById('leftCol').style.display = 'grid';
        this.$data.slideout = false
      }
    },
    showDoorStatusModal(addr) {
      this.$modal.show("doorStatusModal", { add: addr });
    },
    showPhoneModal(addr) {
      this.$modal.show("phoneModal", { add: addr });
    },
    showPhoneDNCModal(addr) {
      this.$modal.show("phoneDNCModal", { add: addr });
    },
    showLetterModal(addr) {
      this.$modal.show("letterModal", { add: addr });
    },
    showLetterDNCModal(addr) {
      this.$modal.show("letterDNCModal", { add: addr });
    },
    showNotesModal(addr) {
      this.$modal.show("notesModal", {add: addr});
    },
    showInfoModal(addr) {
      this.$modal.show("infoModal", {add: addr});
    },
    addToEditing(addr) {
      this.$data.editingAddress = JSON.parse(JSON.stringify(addr));
    },
    truncateSTR(str,n){
      return truncate(str,n)
    },
    doorStatusDisplay(DS){
      var endList = []
      var typeGiven = null
      if(Array.isArray(DS)) typeGiven = 'array'
      if(typeGiven === 'array'){
        var newDSObj = {'No Trespassing':false,'Do Not Call':false}

        for(var q=0;q<DS.length;q++){

          if(DS[q].includes('res')){
            newDSObj['No Trespassing'] = true
          }
          if(DS[q].includes('all') && !DS[q].includes('res')){
            newDSObj['Do Not Call'] = true
          } 
        }
        DS = newDSObj
      }
      endList = this.lodash.keys(this.lodash.pickBy(DS))
      var finalString = endList.length > 1 ? endList.length + " Door Status'"
                        : endList.length === 1
                        ? endList[0]
                        : "none"
      return finalString

    },
    valid(add){
      //valid checks whole address
      var val = false
      if(this.valididateHN(add.houseNumber)){
        val = true
      }
      return val
    },
    valididateHN(hNumber){
      //validates HN
      var val = false
      if(hNumber.trim().length >= 1){
        val = true
      }
      return val
    },
    addChildAddr(territory,street){
      var child = territory.terInfo.childAddress(street.streetName)
      var payload = {child:child,street:street.streetName}
      this.$store.commit('addTempChild',payload)
      this.$data.bodyKey++
    },
    addStreet(territory){
      var streetName = prompt('New Street Name')
      var street = {streetName:streetName}
      this.addChildAddr(territory,street)
      this.$data.bodyKey++
    },
    deleteAddr(addr){
      if(confirm('Are you sure you want to Delete this Address?')){
        //delete
        this.$store.dispatch('deleteAddr', addr)
        this.$data.bodyKey++
      } else {
        //nothing
      }
    }
  },
};
</script>

<style scoped>
@import "./css/editingPage.css";
</style>
