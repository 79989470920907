import { db, auth } from "./../db";
import { v4 as uuidv4 } from "uuid";
import { Territory } from "../models/territory";
import { Address } from "../models/address";
import store from "../store";


//User
export async function login(payload) {
  //determine if user is in fs
  var email = payload.email.toLowerCase();
  var password = payload.password;
  var name = payload.name;
  var userInFs = false;
  await db
    .collection("users")
    .where("email", "==", email)
    .get()
    .then((data) => {
      if (data.docs.length > 0) {
        userInFs = true;
      }
    });
  if (userInFs == false) {
    //create user
    var user = await signUpUser(email, password, name)
    await addUserToFs(user, password, name);
    await auth.signInWithEmailAndPassword(email, password).catch((err) => {
      alert(err.message)
    });
  } else {
    auth
      .signInWithEmailAndPassword(email, password)
      .catch((err)=>{
        alert(err.message)
      })
  }
}

async function signUpUser(email, password) {
  var user;
  await auth.createUserWithEmailAndPassword(email, password).then((data) => {
    user = data.user;
  }).catch((err)=>{
    alert(err.message)
  })
  return user;
}

async function addUserToFs(user, password, name) {
  db.collection("users")
    .doc(user.uid)
    .set({
      email: user.email,
      password: password,
      name: name,
      LastLoggedIn: Date.now(),
      perm: 0,
      approved: false,
    })
    .then(() => {
      console.log("add user to fs success");
    });
}

export async function getFsUser(user) {
  var newUser;
  await db
    .collection("users")
    .doc(user.uid)
    .get()
    .then((user) => {
      newUser = user.data();
    });
  return newUser;
}
//End User

//////////////////////////////////////////////////Territory
export async function getTerritories() {
  var ters = [];
  var tersID = []
  await db
    .collection("territories")
    .orderBy("territoryNumber")
    .get()
    .then((response) => {
      for (var i = 0; i < response.docs.length; i++) {
        ters.push(response.docs[i].data())
        tersID.push(response.docs[i].id)
        if(ters[i].checkoutLogs === undefined){
          ters[i].checkoutLogs = [{'status': 'checkedIn'}]
        }
      }
    });
  var terOBJs = []
  for(var i=0; i < ters.length; i++){
    var ter = ters[i]
    ter.territoryID =  tersID[i]
    terOBJs.push( 
      new Territory(ter)
    )
  }
  return terOBJs;
}

export function getSortedTer(ters) {
  //ters.unshift({ zeroist: null });
  var sorted = [];
  for (var i = -1; i < Math.floor(ters.length / 10); i++) {
    sorted.push([]);
  }
  for (var j = 0; j < ters.length; j++) {
    var place = Math.floor(j / 10);
    sorted[place].push(ters[j]);
  }
  return sorted;
}

export async function getTerritoryContents(terID) {
  var territoryinfo = store.state.Territories.filter(obj => {
    return obj.territoryID === terID
  }); 
  territoryinfo = territoryinfo[0]

  //get Addresses
  var addresses = [];
  await db
    .collection("addresses")
    .where("inTrash", "==", false)
    .where("territoryReference", "==", terID)
    .orderBy("houseNumber")
    .get()
    .then((response) => {
      response.docs.forEach(function(entry){
        addresses.push(entry)
      })
    });
  
    var newAddresses = addresses.map(function(curr){
      var addrInfo = curr.data()
      return new Address(addrInfo)
    })

  var sortedStreets = sortStreets(newAddresses,addresses)

  var territory = {
    terInfo: territoryinfo,
    data: sortedStreets,
  };
  return territory;
}

export function sortStreets(newAddresses,addresses){
  //sort into streets
  var Sstreets = newAddresses.map(function(curr){
    return curr.streetName
  })
  Sstreets = [...new Set(Sstreets)];

  var sortedStreets = {
    streets: [],
  };

  for (var p = 0; p < Sstreets.length; p++) {
    sortedStreets.streets[p] = {
      uid: uuidv4(),
      streetName: Sstreets[p],
      addresses: [],
    };
    var tempArray = [];
    for (var a = 0; a < addresses.length; a++) {
      //
      var adID = addresses[a].id;
      var currAddr = newAddresses[a];
      currAddr.addressID = adID;
      if (currAddr.streetName == Sstreets[p]) {
        tempArray.push(currAddr);
      }
    }
    sortedStreets.streets[p].addresses = tempArray;
  }
  return sortedStreets
}