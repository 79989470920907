<template>
  <div>
    <modal name='doorStatusModal'
    :width="250"
    height="auto"
    :draggable="true"
    :focus-trap="true"
    @before-open='beforeOpen' 
    @before-close='beforeClose'>
    <div id="modal-container">
      <div id="modalTitle">Door Status</div>
      <div v-for='item in Object.keys(options)' :key='item.index'>
        <input type="checkbox" v-model='options[item]'>
        <label for="checkbox">{{item}}</label>
      </div>
    </div>
    </modal>
  </div>
</template>

<script>
import { createLog } from './../../../functions/supporting'
export default {
  name: 'doorStatusModal',
  data(){
    return {
      add: {},
      editing: '',
      options: {'No Trespassing':false,'Do Not Call':false}
    }
  },
  methods:{
    beforeOpen(event){
      this.$data.add = event.params.add
      var DS = event.params.add.doorStatus

      //legacy code to turn arrays to objects from original scrape
      var typeGiven = null
      if(Object.prototype.toString.call(DS) === '[object Object]') typeGiven = 'object'
      if(Array.isArray(DS)) typeGiven = 'array'
      if(typeGiven === 'array'){

        var newDSObj = {'No Trespassing':false,'Do Not Call':false}

        for(var q=0;q<DS.length;q++){

          if(DS[q].includes('res')){
            newDSObj['No Trespassing'] = true
          }
          if(DS[q].includes('all') && !DS[q].includes('res')){
            newDSObj['Do Not Call'] = true
          } 
        }
        event.params.add.doorStatus = newDSObj
        

      }
      //end of legacy

      for(const prop in this.$data.add.doorStatus){
        this.$data.options[prop] = this.$data.add.doorStatus[prop]
      }
      this.addToEditing(event.params.add)
    },
    beforeClose(){
      this.$data.add.doorStatus = this.$data.options
      //this needs editing to all modals!!
      //this.editqueue(this.$data.add)
      var tempStatus = Object.assign({},this.$data.editing)
      this.$data.add.updateAddr(tempStatus,'doorStatus')

      this.$data.options = {'No Trespassing':false,'Do Not Call':false}
    },
    addEmpty(){
      var count = 0
      for (const ele of this.$data.add.doorStatus){
        if(ele === ''){
          count++
        }
      }
      if(count === 0){
          this.$data.add.doorStatus.push('')
      }
    },
    addToEditing(add){
      this.$data.editing = JSON.parse(JSON.stringify(add))
    },
    editqueue(needsEdit){
      var pastAddr = JSON.parse(JSON.stringify(this.$data.editing))
      var newAddr = JSON.parse(JSON.stringify(needsEdit))
      if(!this.lodash.isEqual(newAddr, pastAddr)){
        for(const prop in newAddr){
          if(!this.lodash.isEqual(newAddr[prop],pastAddr[prop])){
            console.log('DOOR')
            var log = createLog(newAddr[prop], pastAddr[prop], "edit ",prop);
            needsEdit.addressLogs.push(log)
          }
        }
        this.$store.commit('appendEdits',needsEdit)
      }
    },
    deleteItem(index){
      this.$data.add.doorStatus.splice(index,1)
    }
  }
}
</script>