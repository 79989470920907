<template>
    <div id="logContainer">
        <div id="log" v-on:click="extOpen = !extOpen">
            <div class="desc">
                <div class="descBox">{{this.log.field}}</div>
            </div>
            <div class="date">
                <div class="dateBox">{{this.log.time}}</div>
            </div>
            <div id="logExt">
                <div id="logExtBefore" v-show="extOpen">
                    <div class="subtitle">Before</div> 
                    <div v-if="typeof log.from === 'string'">
                        <div class="logString">{{log.from}}</div>
                    </div>
                    <div v-else-if="Array.isArray(log.from) === true">
                        <div v-if="log.field === 'phoneNumbers' || log.field === 'phoneDNC'">
                            <div v-for="item in log.from" :key="item.index">
                            {{item | tel}}
                        </div>
                        </div>
                        <div v-else v-for="item in log.from" :key="item.index">
                            {{item}}
                        </div>
                    </div>
                    <div v-else-if="Array.isArray(log.from) === false && typeof log.from === 'object' ">
                        <div v-if="log.field === 'doorStatus'">
                            <div>
                            Do Not Call:{{log.from['Do Not Call']}}  
                            </div>
                            <div>
                            No Trespassing:{{log.from['No Trespassing']}} 
                            </div>
                            
                        </div>
                        <div v-else v-for="(value,key) in log.from" :key='key'>
                            {{key}}:{{value}}
                        </div>
                    </div>
                    <div v-else>
                        Oops there was and error
                    </div>
                </div>
                <div id="logExtAfter" v-show="extOpen">
                    <div class="subtitle">After</div>
                    <div v-if="typeof log.to === 'string'">
                        <div class="logString">{{log.to}}</div>
                    </div>
                    <div v-else-if="Array.isArray(log.to) === true">
                        <div v-if="log.field === 'phoneNumbers' || log.field === 'phoneDNC'">
                            <div v-for="item in log.to" :key="item.index">
                            {{item | tel}}
                        </div>
                        </div>
                        <div v-else v-for="item in log.to" :key="item.index">
                            {{item}}
                        </div>
                    </div>
                    <div v-else-if="Array.isArray(log.to) === false && typeof log.to === 'object' ">
                        <div v-if="log.field === 'doorStatus'">
                            <div>
                            Do Not Call:{{log.to['Do Not Call']}}  
                            </div>
                            <div>
                            No Trespassing:{{log.to['No Trespassing']}} 
                            </div>
                            
                        </div>
                        <div v-else v-for="(value,key) in log.to" :key='key'>
                            {{key}}:{{value}}
                        </div>
                    </div>
                    <div v-else>
                        Oops there was and error
                    </div>
                </div>
            </div>
            <div id="logAtrib" class="subtitle" v-show="extOpen">
                Changed by: {{log.by}}
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name:"log",
    data(){
        return {
            log: {},
            extOpen: false,
        }
    },
    props: {
        logData:{
            type: Object,
        }
    },
    methods:{

    },
    created(){
        var wLog = JSON.parse(JSON.stringify(this.logData))
        if(typeof(wLog.time) === 'object'){
            wLog.time = this.$moment.unix(wLog.time.seconds).format("MMM D YYYY TH:m")
        }
        wLog.time = this.$moment(wLog.time,"MMM D YYYY TH:m").format("M/D/YY@h:mmA")
        this.log = wLog
    },
    filters:{
        tel: function(val){
            var cleaned = ('' + val).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3]
            }
            return null
        }
    }
    
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;1,400;1,500&display=swap');
    #logContainer{
        padding:5px 5px 5px 0px;
    }
    #log{
        display:grid;
        grid-template-columns: 1fr 1fr;
    }
    .desc{
        columns:1/2;
    }
    .descBox{
        font-family: 'Arimo', sans-serif;
        font-weight: 500;
        user-select: none;
    }
    .date{
        columns:2/3;
    }
    .dateBox{
        font-family: 'Arimo', sans-serif;
        font-weight: 500;
        user-select: none;
    }
    #logExt{
        grid-column: 1/3;
        grid-row:2/3;
        display:grid;
        grid-template-columns: 1fr 1fr;

        padding-left: 10px;
    }
    .subtitle{
        font-family: 'Arimo', sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        color:grey;
    }
    #logExtBefore{
        font-family: 'Arimo', sans-serif;
        font-size: 15px;
        grid-column:1/2;
        grid-row: 2/3;
    }
    #logExtAfter{
        font-family: 'Arimo', sans-serif;
        font-size: 15px;
        grid-column:2/3;
        grid-row: 2/3;
    }
    #logAtrib{
        padding-top: 5px;
        padding-left:10px;
        grid-column: 1/3;
        grid-row: 3/4;
    }
</style>