<template>
  <div id="container">
    <div id="header">
      <div id="title">terrdb</div>
      <div id="nav">
        <div v-for="page in navList" :key="page.index">
          <div class="navBtn" v-on:click="navigate(page)">{{ page }}</div>
        </div>
      </div>
      <div id="userInfo">
        {{ user.name + "," }}
        <span id="logout" v-on:click="logout">logout</span>
      </div>
    </div>
    <div id="leftCol">
      <div id="territories">
        <div
          v-for="block in sorted"
          :key="block.territoryName"
          class="terBlock"
        >
          <div
            v-for="ter in block"
            :key="ter.territoryNumber"
            v-on:click="changeSelected(ter.territoryNumber)"
            :class="terClass(ter)"
            class="ter"
          >
            <div class="terNumber" :class="selectedClassNum(ter)">
              {{ ter.territoryNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="rightCol">
      <div v-if="noTerSelected" id="checkoutLandingPage">charts and stuff</div>
      <checkoutPage v-else></checkoutPage>
    </div>
  </div>
</template>

<script>
import store from "../../store"
import checkoutPage from "./checkoutPage"
export default {
  components: {
    checkoutPage: checkoutPage,
  },
  data() {
    return {
      selected: "",
      noTerSelected: true,
      navList: ["home", "edit", "checkout", "admin"],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    sorted() {
      return this.$store.getters.sortedTerritories;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    navigate(route) {
      if(route === "checkout"){
        this.selected = "",
        this.noTerSelected = true
      }
      if(this.$store.state.edits.length === 0){
        this.$store.commit('updateEditTerritory', {})
        this.$router.push("/" + route).catch(() => {});
      } else {
        this.$modal.show("saveModal");
      }
    },
    changeSelected(num) {
      if (this.$store.state.edits.length <= 0) {
        this.$data.selected = num;
        this.$store.dispatch("getTerritory", num).then(() => {
          setTimeout(() => {
            this.$data.noTerSelected = false;
          }, 1000);
          var myDiv = document.getElementById("body");
          try {
            myDiv.scrollTop = 0;
          } catch (err) {
            null;
          }
        });
      } else {
        this.$modal.show("saveModal");
      }
    },
    terClass(ter) {
      if (ter.territoryNumber == this.$data.selected) {
        return "terS";
      } else if (ter.checkoutLogs[0].status == "checkedOut") {
        return "terCheckedOut";
      } else if (ter.checkoutLogs[0].status == "checkedIn") {
        return "terCheckedIn";
      }
    },
    selectedClassNum(ter) {
      if (ter.territoryNumber == this.$data.selected) {
        return "terNumberS";
      } else {
        return "terNumNotSelected";
      }
    },
  },
  beforeCreate: () => {
    store.dispatch("getTers");
  }
};
</script>

<style scoped>
@import "./css/checkout.css";
</style>
