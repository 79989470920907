import store from './../store'
import moment from 'moment'
import lodash from 'lodash'

// export function deepFreeze (o) {
//   console.log(o)
//   Object.freeze(o);

//   Object.getOwnPropertyNames(o).forEach(function (propr) {
//     if (Object.prototype.hasOwnProperty.call(o,propr)
//     && o[propr] !== null
//     && (typeof o[propr] === "object" || typeof o[propr] === "function")
//     && !Object.isFrozen(o[propr])) {
//       deepFreeze(o[propr])
//     }
//   })
  
//   return o;
// }

export function createLog(newVal=null, oldVal=null, type, prop) {
  if(Array.isArray(newVal)) newVal = Object.assign([],newVal)
  var userEmail = store.getters.user.email
  var time = moment().format('MMM D YYYY TH:m')
  var log = {
    'type': type,
    'time': time,
    'from':oldVal,
    'to': newVal,
    'by': userEmail,
    'field': prop,
  }

  return log
}

export function truncate(str, n){
  return (str.length > n) ? str.substr(0, n-1) + '...' : str
}

export function checkDif(a,b){
  a = JSON.parse(JSON.stringify(a))
  b = JSON.parse(JSON.stringify(b))
  //console.log(a.addresses,b.addresses)
  if(lodash.isEqual(a,b)){
    //same
    return false
  } else {
    //diff
    return true
  }
}