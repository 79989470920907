<template>
  <div>
    <modal
      name="phoneModal"
      :width="250"
      height="auto"
      :draggable="true"
      :focus-trap="true"
      @before-open="beforeOpen"
      @before-close="beforeClose"
    >
      <div id='modal-container'>
        <div id="modalTitle">Phone</div>
        <div v-for="(item, index) in add.phoneNumbers" :key="item.index">
          <the-mask v-model="add.phoneNumbers[index]" type='tel' mask="(###) ###-####" placeholder='10 digit phone number' v-bind:class="{notValid:!valItem(add.phoneNumbers[index])}" />
          <span class='delButton' v-on:click='deleteItem(index)' ><img src="https://img.icons8.com/ios/18/000000/minus.png"/></span>
        </div>
        <button v-on:click="addEmpty" class='modal-button'>Add Additional</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { createLog } from "./../../../functions/supporting";
export default {
  name: "phoneModal",
  components:{
  },
  data() {
    return {
      add: {},
      editing: "",
    };
  },
  computed:{   
  },
  methods: {
    beforeOpen(event) {
      this.$data.add = event.params.add;
      this.addToEditing(event.params.add);
    },
    beforeClose(event) {
      for (const eleme of this.$data.add.phoneNumbers) {
        var index = this.$data.add.phoneNumbers.indexOf(eleme);
        if (eleme === "") {
          this.$data.add.phoneNumbers.splice(index, 1);
        }
      }
      if(this.valid()){
        //this.editqueue(this.$data.add)
        var tempStatus = Object.assign({},this.$data.editing)
        this.$data.add.updateAddr(tempStatus,'phoneNumbers')
      } else {
        console.log('not valid')
        event.cancel()
      }
      
    },
    addEmpty() {
      var count = 0;
      for (const ele of this.$data.add.phoneNumbers) {
        if (ele === "") {
          count++;
        }
      }
      if (count === 0) {
        this.$data.add.phoneNumbers.push("");
      }
    },
    addToEditing(add) {
      this.$data.editing = JSON.parse(JSON.stringify(add));
    },
    editqueue(needsEdit) {
      var pastAddr = JSON.parse(JSON.stringify(this.$data.editing));
      var newAddr = JSON.parse(JSON.stringify(needsEdit));
      if (!this.lodash.isEqual(newAddr, pastAddr)) {
        for (const prop in newAddr) {
          if (!this.lodash.isEqual(newAddr[prop], pastAddr[prop])) {
            console.log('P')
            var log = createLog(newAddr[prop], pastAddr[prop], "edit ", prop);
            needsEdit.addressLogs.push(log);
          }
        }
        this.$store.commit("appendEdits", needsEdit);
      }
    },
    deleteItem(index){
      this.$data.add.phoneNumbers.splice(index,1)
    },
    valItem(item){
      var val = true
      var checkLen = item.replace(/[^0-9]/g, '')
      if(checkLen.length < 10){
        val = false
      }
      return val
    },
    valid(){
      var valid = true
      for(var item of this.$data.add.phoneNumbers){
        var valCheck = item.replace(/[^0-9]/g, '')
        if(valCheck.length < 10){
          valid = false
        } 
      }
      return valid
    },
  }
};
</script>
<style>
@import './modal-css/listModal.css';
</style>