import { db } from '../db';
import { createLog, checkDif } from '../functions/supporting'

export class Address{
  constructor(add){
    this.addressCity = add.addressCity,
    this.addressLogs = add.addressLogs,
    this.addressNotes = add.addressNotes,
    this.addressState = add.addressState,
    this.addressUUID = add.addressUUID,
    this.addressZipcode = add.addressZipcode,
    this.createDate = add.createDate,
    this.editDate = add.editDate,
    this.doorStatus = add.doorStatus,
    this.houseNumber = add.houseNumber,
    this.inTrash = add.inTrash,
    this.letterDNC = add.letterDNC,
    this.phoneDNC = add.phoneDNC,
    this.phoneNumbers = add.phoneNumbers,
    this.resNames = add.resNames,
    this.streetName = add.streetName,
    this.territoryReference = add.territoryReference
  }

  async updateAddr(orig,param){
    this.cleanAddr()
    //check if the current address object is different from the saved version before edit
    if(checkDif(orig,this)){
      //console.log('needs to be updated', this[param])
      //create log
      var log = createLog(this[param],orig[param],'edit',param)
      this.addressLogs.push(log)

      var ADDR = await db
        .collection('addresses')
        .where('addressUUID', '==', this.addressUUID)
        .get()
        .then((response) => {
          return response.docs
        })

       if(ADDR.length === 1){ //check for duplicate UUID's (doubt there is going to be tho)
        ADDR = ADDR[0]

        await db.collection('addresses').doc(ADDR.id).set({
          addressCity: this.addressCity,
          addressLogs: this.addressLogs,
          addressNotes: this.addressNotes,
          addressState: this.addressState,
          addressUUID: this.addressUUID,
          addressZipcode: this.addressZipcode,
          createDate: this.createDate,
          editDate: this.editDate,
          doorStatus: this.doorStatus,
          houseNumber: this.houseNumber.trim(),
          inTrash: this.inTrash,
          letterDNC: this.letterDNC,
          phoneDNC: this.phoneDNC,
          phoneNumbers: this.phoneNumbers,
          resNames: this.resNames,
          streetName: this.streetName,
          territoryReference: this.territoryReference
        },{merge:true})

        //console.log('addr updated')

      } else if(ADDR.length === 0){
        this.addAddr()
      }else {
        alert('STOP: address update unsuccessful')
      } 
    } else {
        console.log('stayed the same, no update')
    }
  }

  async addAddr(){
    //test if there is any address duplicate
    //console.log(this.houseNumber,this.streetName)
    var testQuery = await db.collection('addresses')
        .where('houseNumber','==', this.houseNumber)
        .where('streetName','==', this.streetName)
        .get()
    
    //console.log(testQuery.docs.length,this)

        if(testQuery.docs.length < 1){
          //creation
        await db.collection('addresses').add({
              addressCity: this.addressCity,
              addressLogs: this.addressLogs,
              addressNotes: this.addressNotes,
              addressState: this.addressState,
              addressUUID: this.addressUUID,
              addressZipcode: this.addressZipcode,
              createDate: this.createDate,
              editDate: this.editDate,
              doorStatus: this.doorStatus,
              houseNumber: this.houseNumber.trim(),
              inTrash: this.inTrash,
              letterDNC: this.letterDNC,
              phoneDNC: this.phoneDNC,
              phoneNumbers: this.phoneNumbers,
              resNames: this.resNames,
              streetName: this.streetName,
              territoryReference: this.territoryReference
            })
        } else {
          console.log('Tried to create a duplicate address. address creation failed')
          alert('Duplicate Address Detected. tell micah')
        }

    
  }
  async deleteAddr(){
    var ADDR = await db
        .collection('addresses')
        .where('addressUUID', '==', this.addressUUID)
        .get()
    if(ADDR.docs.length === 1){
      await db.collection('addresses').doc(ADDR.docs[0].id).set({
        inTrash: true,
      },{merge:true})
    } else if(ADDR.docs.length === 0){
      console.log('nothing to delete')
    }else {
      alert('deleteAddr: more than one address with UUID: '+ this.addressUUID)
    }
    
  }
  cleanAddr(){
    for(var i=0; i <= this.letterDNC.length; i++){
      var targ = this.letterDNC[i]
      if (!(/\S/.test(targ))) {
        console.log(`deleted a listing -> ${targ}`)
        this.letterDNC.splice(i,1)
      }
    }
    for(i=0; i <= this.phoneDNC.length; i++){
      targ = this.phoneDNC[i]
      if (!(/\S/.test(targ))) {
        console.log(`deleted a listing -> ${targ}`)
        this.phoneDNC.splice(i,1)
      }
    }
  }
}