import { Address } from './address';
import { v4 as uuidv4 } from "uuid";
import { checkDif } from '../functions/supporting';
import { createLog } from '../functions/supporting'
import { db } from '../db';


export class Territory{
  constructor(ter){
    this.addresses = ter.addresses || [], 
    this.createDate = ter.createDate || Math.floor(Date.now()/1000), 
    this.editDate = ter.editDate || this.createDate, 
    this.inTrash = ter.inTrash || false,
    this.territoryCity = ter.territoryCity || 'Springfield',
    this.territoryDesc = ter.territoryDesc || 'noDesc', 
    this.territoryLogs = ter.territoryLogs || [], 
    this.territoryMapRef = ter.territoryMapRef || '',
    this.territoryName = ter.territoryName || 'noTerritoryName',
    this.territoryNumber = ter.territoryNumber || 'noTerritoryNumber',
    this.territoryPDFDownload = ter.territoryPDFDownload || 'noPDFDownloadLink',
    this.territorySpecificInstructions = ter.territorySpecificInstructions || [],
    this.territoryState = ter.territoryState || 'Oregon',
    this.territoryType = ter.territoryType || 'noType',
    this.territoryZipcode = ter.territoryZipcode || '97477',
    this.territoryID = ter.territoryID,
    this.territoryUID = ter.territoryUID || uuidv4()
  }//add checkout logs in future

  //methods
  //new address with defaults from parent territory
  childAddress(
    //values with some defaults
    streetName,
    addressCity = this.territoryCity,
    addressLogs = [],
    addressNotes = [],
    addressState = this.territoryState,
    addressUUID = uuidv4(),
    addressZipcode = this.territoryZipcode,
    createDate = Math.floor(Date.now() / 1000),
    editDate = Math.floor(Date.now() / 1000),
    doorStatus = {'Do Not Call':false, 'No Trespassing':false},
    houseNumber = 'noneSet/new',
    inTrash = false,
    letterDNC = [],
    phoneDNC = [],
    phoneNumbers = [],
    resNames = [],
    territoryReference = this.territoryID
  )
  {
    //original for the sake of difference detection and log creation
    // var pastTerVer = Object.assign({},this)
    // pastTerVer = JSON.parse(JSON.stringify(pastTerVer))

    //Creates a child address, with default values from territory
    var CA = new Address(
      //object 'add'
      {
        addressCity:addressCity,
        addressLogs:addressLogs,
        addressNotes:addressNotes,
        addressState:addressState,
        addressUUID:addressUUID,
        addressZipcode:addressZipcode,
        createDate:createDate,
        editDate:editDate,
        doorStatus:doorStatus,
        houseNumber:houseNumber,
        inTrash:inTrash,
        letterDNC:letterDNC,
        phoneDNC:phoneDNC,
        phoneNumbers:phoneNumbers,
        resNames:resNames,
        streetName:streetName,
        territoryReference:territoryReference
      }
    )
    //this.addresses.push(CA.addressUUID)
    //this.updateTer(pastTerVer,'addresses')
    return CA
  }

    async updateTer(orig,param){

      if(checkDif(orig,this)){
        console.log('territory needs updating')
        //log creation and push
        var log = createLog(this[param],orig[param],'edit',param)
        this.territoryLogs.push(log)

        await db
          .collection('territories')
          .doc(this.territoryID).set({
            addresses: this.addresses,
            createDate: this.createDate,
            editDate: this.editDate,
            inTrash: this.inTrash,
            territoryCity: this.territoryCity,
            territoryDesc: this.territoryDesc,
            territoryLogs: this.territoryLogs,
            territoryMapRef: this.territoryMapRef,
            territoryName: this.territoryName,
            territoryNumber: this.territoryNumber,
            territoryPDFDownload: this.territoryPDFDownload,
            territorySpecificInstructions: this.territorySpecificInstructions,
            territoryState: this.territoryState,
            territoryType: this.territoryType,
            territoryZipcode: this.territoryZipcode,
            territoryUID: this.territoryUID
          },{merge:true})
          .then(()=>console.log('ter updated'))
      } else {
        console.log('stayed the same, no update')
      }
    }

    async addTer(){
      var testQuery = await db.collection('territories')
        .where('territoryNumber','==', this.territoryNumber)
        .where('territoryType','==', this.territoryType)
        .get()

      if(testQuery.docs.length < 1){
        await db
          .collection('territories')
          .add({
            addresses: this.addresses,
            createDate: this.createDate,
            editDate: this.editDate,
            inTrash: this.inTrash,
            territoryCity: this.territoryCity,
            territoryDesc: this.territoryDesc,
            territoryLogs: this.territoryLogs,
            territoryMapRef: this.territoryMapRef,
            territoryName: this.territoryName,
            territoryNumber: this.territoryNumber,
            territoryPDFDownload: this.territoryPDFDownload,
            territorySpecificInstructions: this.territorySpecificInstructions,
            territoryState: this.territoryState,
            territoryType: this.territoryType,
            territoryZipcode: this.territoryZipcode,
            territoryUID: this.territoryUID
          })
          .then(()=>console.log('ter added'))
      }else {
        alert('duplicate territories, try changing number or type')
      }

      
    }
}