<template>
  <div>
    <modal
      name="notesModal"
      :width="300"
      height="auto"
      :draggable="true"
      :focus-trap="true"
      @before-open="beforeOpen"
      @before-close="beforeClose"
    >
      <div id='modal-container'>
        <div id="modalTitle">Notes</div>
        <div v-for="(item, index) in add.addressNotes" :key="item.index">
          <input v-model="add.addressNotes[index]" type="text" />
          <span class='delButton' v-on:click='deleteItem(index)' ><img src="https://img.icons8.com/ios/18/000000/minus.png"/></span>
        </div>
        <button v-on:click="addEmpty" class='modal-button'>Add Additional</button>
      </div>
    </modal>
  </div>
</template>

<script>
//import { createLog } from "./../../../functions/supporting";
export default {
  name: "notesModal",
  data() {
    return {
      add: {},
      editing: '',
    };
  },
  methods: {
    beforeOpen(event) {
      this.$data.add = event.params.add;
      this.addToEditing(event.params.add);
    },
    beforeClose() {
      for (const eleme of this.$data.add.addressNotes) {
        if (eleme === "") {
          var index = this.$data.add.addressNotes.indexOf(eleme);
          this.$data.add.addressNotes.splice(index, 1);
        }
      }
      //this.editqueue(this.$data.add);
      var tempStatus = Object.assign({},this.$data.editing)
      this.$data.add.updateAddr(tempStatus,'addressNotes')
    },
    addEmpty() {
      var count = 0;
      for (const ele of this.$data.add.addressNotes) {
        if (ele === "") {
          count++;
        }
      }
      if (count === 0) {
        this.$data.add.addressNotes.push("");
      }
    },
    addToEditing(add) {
      this.$data.editing = JSON.parse(JSON.stringify(add));
    },
    // editqueue(needsEdit) {
    //   var pastAddr = JSON.parse(JSON.stringify(this.$data.editing));
    //   var newAddr = JSON.parse(JSON.stringify(needsEdit));
    //   if (!this.lodash.isEqual(newAddr, pastAddr)) {
    //     for (const prop in newAddr) {
    //       if (!this.lodash.isEqual(newAddr[prop], pastAddr[prop])) {
    //         console.log('N')
    //         var log = createLog(newAddr[prop], pastAddr[prop], "edit ", prop);
    //         needsEdit.addressLogs.push(log);
    //       }
    //     }
    //     this.$store.commit("appendEdits", needsEdit);
    //   }
    // },
    deleteItem(index){
      this.$data.add.addressNotes.splice(index,1)
    }
  }
};
</script>