<template>
    <div id='container'>
        <div id='body'>
            <div class="title" v-bind:class='approved()'>terrdb</div>
            <div v-if='error'>{{error}}</div>
            <form action='#' @submit.prevent='logIn'>
                <input id='email' type='email' name='email' placeholder="email . . ." value required autofocus autocomplete="on" v-model='form.email'/>
                <input id='password' type='password' name='password' placeholder="password (8 char. min) . . ." value required autocomplete="on" v-model='form.password'/>
                <input id='name' type='text' name='name' placeholder="name (first&last) . . ." value v-model='form.name'/>
                <button id='submitButton' type='submit'>login</button>
            </form>
            <!-- <button v-on:click='logout()'>logout</button> -->
        </div>
    </div>
</template>

<script>
import store from '../store'
    //import { mapGetters } from 'vuex'
    export default {
        data(){
            return{
                form: {
                    email: '',
                    password: '',
                    name: '',
                },
                error: null
            }

        },
        computed:{
            user(){ return store.getters.user}
        },
        methods: {
            logIn(){
                store.dispatch({
                    type: 'login',
                    email: this.form.email,
                    password: this.form.password,
                    name: this.form.name,
                })
            },
            logout(){
                this.$store.dispatch('logout')
            },
            approved(){
                var result = []
                if (this.user != undefined) {
                    if(!this.user.approved)
                    result.push('notApproved')
                }
                return result
            }
        }
    }
</script>

<style scoped>

#container{
    font-family: 'Open Sans', sans-serif;
    height: 100vh;
    width: 100vw;
}
.title{
    font-size: 50px;
    color:black;
}
#body{
    height: 100%;
    position:absolute;
    top:70%;
    left:50%;
    transform: translate(-50%, -50%);
}
form input{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    display:block;
    border-width: 3px;
    height:35px;
    width:270px;
    margin-bottom:5px;
    padding-left:5px;
    border: 2px solid black;
    font-size: 16px;
    text-align: left;
}
form input:focus{
    outline:none;
}

form input::placeholder{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color:black;
}

#submitButton{
    float:right;
    border:2px solid black;
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    height:35px;
    width:70px;
    font-size:1.5vh;
    text-align: center;
}

.notApproved {
    color:red;
}

</style>