<template>
    <div id="addrObjectContainer" :key='compUpdate'>
        <!-- Location Block -->
        <div id="locationBlock" class='infoBlock'>
            <div class="subtitle">location</div>
            <div class="dataBlockGrid">
                <!-- House Number -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">House Number</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><input type="text" class='dPieceDataTextField' v-model="add.houseNumber" @change="changed('houseNumber')"></div>
                    </div>
                </div>
                <!-- End House Number -->
                <!-- Street -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Street</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><input type="text" class='dPieceDataTextField' v-model="add.streetName" @change="changed('streetName')"></div>
                    </div>
                </div>
                <!-- End Street -->
                <!-- City -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">City</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><input type="text" class='dPieceDataTextField' v-model="add.addressCity" @change="changed('addressCity')"></div>
                    </div>
                </div>
                <!-- End City -->
                <!-- State -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">State</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><input type="text" class='dPieceDataTextField' v-model="add.addressState" @change="changed('addressState')"></div>
                    </div>
                </div>
                <!-- End State -->
                <!-- Zipcode -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Zipcode</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><input type="text" class='dPieceDataTextField' v-model="add.addressZipcode" @change="changed('addressZipcode')"></div>
                    </div>
                </div>
                <!-- End Zipcode -->
            </div>
        </div>
        <!-- End Location Block -->
        <!-- Status Block -->
        <div id="statusBlock" class='infoBlock'>
            <div class="subtitle">status</div>
            <div class="dataBlockGrid">
                <!-- Door -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Door</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div class="dPieceDataText" style="cursor:pointer;" v-on:click="toggleOpen('doorStatus')">{{doorStatusDisplay(add.doorStatus)}}</div></div>
                    </div>
                        <doorStatusSelect v-show="doorStatusShow"/>
                </div>
                <!-- End Door -->
                <!-- Letter -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Letter DNC</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText' style="cursor:pointer;" v-on:click="toggleOpen('letterStatusDNC')">{{add.letterDNC.length}} Names</div></div>
                    </div>
                    <addressListStatusEditor listType="letterDNC" placeHold="First Last" v-show="letterStatusDNCShow"/>
                </div>
                <!-- End Letter -->
                <!-- Phone -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Phone DNC</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText' style="cursor:pointer;" v-on:click="toggleOpen('phoneStatusDNC')">{{add.phoneDNC.length}} Numbers</div></div>
                    </div>
                    <addressPhoneListStatusEditor listType="phoneDNC" placeHold="(###) ###-####" v-show="phoneStatusDNCShow" @closeCheck='closeCheck'/>
                </div>
                <!-- End Phone -->
            </div>
        </div>
        <!-- End Status Block -->
        <!-- Names & Numbers Block -->
        <div id="statusBlock" class='infoBlock'>
            <div class="subtitle">names & numbers</div>
            <div class="dataBlockGrid">
                <!-- Letter -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Letter</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText' style="cursor:pointer;" v-on:click="toggleOpen('letter')">{{add.resNames.length}} Names</div></div>
                    </div>
                    <addressListStatusEditor listType="resNames" placeHold="First Last" v-show="letterShow"/>
                </div>
                <!-- End Letter -->
                <!-- Phone -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Phone</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText' style="cursor:pointer;" v-on:click="toggleOpen('phone')">{{add.phoneNumbers.length}} Numbers</div></div>
                    </div>
                    <addressPhoneListStatusEditor listType="phoneNumbers" placeHold="(###) ###-####" v-show="phoneShow" @closeCheck='closeCheck'/>
                </div>
                <!-- End Phone -->
            </div>
        </div>
        <!-- End Names & Numbers Block -->
        <!-- Address Info Block -->
        <div id="statusBlock" class='infoBlock'>
            <div class="subtitle">Address Info</div>
            <div class="dataBlockGrid">
                <!-- Notes -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Notes</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText' style="cursor:pointer;" v-on:click="toggleOpen('notes')">{{add.addressNotes.length}} Notes</div></div>
                    </div>
                    <addressListStatusEditor listType="addressNotes" placeHold="note" v-show="notesShow"/>
                </div>
                <!-- End Notes -->
                <!-- Create Date -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Create Date</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText'>{{add.createDate.seconds | moment("h:mA M/DD/YY")}}</div></div>
                    </div>
                </div>
                <!-- End Create Date -->
                <!-- Edit Date -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Edit Date</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText'>{{add.editDate.seconds | moment("h:mA M/DD/YY")}}</div></div>
                    </div>
                </div>
                <!-- End Edit Date -->
            </div>
        </div>
        <!-- End Address Info Block -->
        <!-- Object Info Block -->
        <div id="statusBlock" class='infoBlock'>
            <div class="subtitle">Object Info</div>
            <div class="dataBlockGrid">
                <!-- Address UUID -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Address UUID</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText hoverFull'>{{add.addressUUID}}</div></div>
                    </div>
                    <addressListStatusEditor listType="addressNotes" placeHold="note" v-show="notesShow"/>
                </div>
                <!-- End Address UUID -->
                <!-- Territory ID -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">Territory ID</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText'>{{add.territoryReference}}</div></div>
                    </div>
                </div>
                <!-- End Territory ID -->
                <!-- In Trash -->
                <div class="dPiece">
                    <div class="dPieceIndex"><div class="centerV">In Trash</div></div>
                    <div class="dPieceData">
                        <div class="centerV"><div type="text" class='dPieceDataText'>{{add.inTrash}}</div></div>
                    </div>
                </div>
                <!-- End In Trash -->
            </div>
        </div>
        <!-- End Object Info Block -->
        
    </div>
</template>

<script>
import DoorStatusSelect from './AddressDoorStatusSelector.vue'
import AddressListStatusEditor from './AddressListStatusEditor.vue'
import AddressPhoneListStatusEditor from './AddressPhoneListStatusEditor.vue'

export default {
    components:{
        DoorStatusSelect,
        AddressListStatusEditor,
        AddressPhoneListStatusEditor
    },
    data() {
        return {
            add: {},
            origAdd: {},
            doorStatusShow: false,
            letterStatusDNCShow: false,
            phoneStatusDNCShow: false,
            letterShow: false,
            phoneShow: false,
            notesShow: false,
            compUpdate: 0,
        }
    },
    methods: {
        doorStatusDisplay(DS){
        var endList = this.lodash.keys(this.lodash.pickBy(DS))
        var finalString = endList.length > 1 ? endList.length + " Door Status'"
                            : endList.length === 1
                            ? endList[0]
                            : "none"
        return finalString

        },
        toggleOpen(dataPiece){
            if(dataPiece === 'doorStatus'){
                this.$data.doorStatusShow = !this.$data.doorStatusShow
            }
            else if (dataPiece === 'letterStatusDNC'){
                this.$data.letterStatusDNCShow = !this.$data.letterStatusDNCShow
            }
            else if (dataPiece === 'phoneStatusDNC'){
                this.$data.phoneStatusDNCShow = !this.$data.phoneStatusDNCShow
            }
            else if (dataPiece === 'phone'){
                this.$data.phoneShow = !this.$data.phoneShow
            }
            else if (dataPiece === 'letter'){
                this.$data.letterShow = !this.$data.letterShow
            }
            else if (dataPiece === 'notes'){
                this.$data.notesShow = !this.$data.notesShow
            }
        },
        updateIt(){
            this.compUpdate++
        },
        closeCheck(check){
            this.$emit('closeCheck',check)
        },
        setData(){
            this.add = this.$store.getters.editAddress
            this.origAdd = JSON.parse(JSON.stringify(this.$store.getters.editAddress))
        },
        changed(param){
            this.$store.getters.editAddress.updateAddr(this.origAdd,param)
            this.setData()
        }
    },
    created(){
        this.setData()
    },
    destroyed(){
        this.doorStatusShow = false;
        this.letterStatusDNCShow = false;
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;1,400;1,500&display=swap');

    #addrObjectContainer{
        width:auto;
        padding-top: 8px;
        display: grid;
        max-height: 650px;
        overflow-y:scroll;
    }
    .infoBlock{
        padding-bottom: 10px;
    }
    .subtitle{
        font-family: 'Arimo', sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        color:grey;
    }
    .dataBlockGrid{
        display:grid;
    }
    .dPiece{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(25px,auto);
        
    }
    .dPiece .dPieceIndex{
        position: relative;
        font-family: 'Arimo', sans-serif;
        font-weight: 500;
        font-size: 18px;
    }
    .dPiece .dPieceData{
        position: relative;
        border-left: 2px solid black;
        overflow-x: hidden;
        padding-left: 10px;
    }
    .dPiece .dPieceData:hover{
        overflow-x:visible;
    }
    .dPiece .dPieceData:hover .hoverFull{
        width:350px;
    }
    .dPiece .dPieceDataText{
        font-family: 'Arimo', sans-serif;
        font-weight: 500;
        font-size: 18px;
    }
    .dPiece .dPieceData .dPieceDataTextField{
        width:95%;
        cursor:text;

        border:none;
        background-image:none;
        background-color:transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    input[type='text']{
        font-family: 'Arimo', sans-serif;
        font-weight: 500;
        font-size: 18px;
        overflow: hidden;
        padding:0px;
    }
    .centerV {
        font-family: inherit;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }



        /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
    transition: all .2s linear;
    }
    .slide-fade-leave-active {
    transition: all .2s linear;
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-7px);
    opacity: 0;
    }
</style>