<template>
  <div>
    <modal
      name='infoModal'
      classes='modal-container'
      width=850px
      height=800px
      :scrollable='false'
      :draggable="true"
      @before-open="beforeOpen"
      @before-close="beforeClose"
    >
      
        <div id="infoCol">
          <div class='colTitle'>INFO/ {{this.$data.add.houseNumber}} {{this.$data.add.streetName}}</div>
          <div id="terBlock">
            <div id="infoTitle">Territory Object<span v-on:click='toggleOpen("terr")'><img v-show='!terrInfoOpen' src="../../../assets/Chevron Down.svg" class='chev'></span><span v-on:click='toggleOpen("terr")'><img v-show='terrInfoOpen' src="../../../assets/Chevron Up.svg" class='chevDown'></span></div>
            <div v-show='terrInfoOpen' class="infoContainer">
              INFO!!!
            </div>
          </div>
          <div id="addrBlock">
            <div id="infoTitle">Address Object<span v-on:click='toggleOpen("addr")'><img v-show='!addrInfoOpen' src="../../../assets/Chevron Down.svg" class='chev'></span><span v-on:click='toggleOpen("addr")'><img v-show='addrInfoOpen' src="../../../assets/Chevron Up.svg" class='chevDown'></span></div>
            <div v-show='addrInfoOpen' class="infoContainer">
              <AddressObject @closeCheck='closeCheck'/>
            </div>
          </div>
        </div>
        <div id="logsCol">
          <div class='colTitle'>LOGS/</div>
          <LogsObject />
        </div>
    </modal>
  </div>
</template>

<script>
import AddressObject from '../../subComponents/AddressObject.vue'
import LogsObject from '../../subComponents/LogsObject.vue'

export default {
  name: "infoModal",
  components: {
    AddressObject,
    LogsObject
  },
  data() {
    return {
      add:{},
      addrInfoOpen: true,
      terrInfoOpen:false,
      okToClose:{
        reason: '',
        canClose: true,
      },
    };
  },
  methods: {
    beforeOpen(event) {
      this.$store.commit('updateEditAddress', event.params.add)
      this.$data.add = this.$store.getters.editAddress
    },
    beforeClose(event){
      if(this.okToClose.canClose === false){
        alert(this.okToClose.reason)
        event.cancel()
        return
      }
      this.$store.commit('updateEditAddress', {})
      this.addrInfoOpen = true
    },
    delAddr(){
      this.$store.dispatch('deleteAddr',this.$data.add)
      this.$modal.hide('infoModal')
    },
    toggleOpen(infoblock){
      if(infoblock === 'addr'){
        this.$data.addrInfoOpen = !this.$data.addrInfoOpen
      }else if (infoblock === 'terr'){
        this.$data.terrInfoOpen = !this.$data.terrInfoOpen
      }
    },
    closeCheck(check){
      this.okToClose = check
    }
  },
};
</script>
<style>
@import './modal-css/infoModal.css';
</style>
