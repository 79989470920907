import firebase from "firebase";
import 'firebase/firebase'

const firebaseConfig = {
    apiKey: "AIzaSyAiQhGZXktTOtQFacAoz4Aj7L6Nd9yJuJI",
    authDomain: "terrdb-41764.firebaseapp.com",
    databaseURL: "https://terrdb-41764.firebaseio.com",
    projectId: "terrdb-41764",
    storageBucket: "terrdb-41764.appspot.com",
    messagingSenderId: "936344675571",
    appId: "1:936344675571:web:8e5a75c1486c118d990386",
    measurementId: "G-S39FHHLDQX"
  };

//const { TimeStamp } = firebase.firestore

export const db = firebase.initializeApp(firebaseConfig).firestore()
export const auth = firebase.auth()
