<template>
  <div id="container">
    <div id="header">
      <div id="title">terrdb2</div>
      <div id="nav">
        <div v-for="page in navList" :key="page.index">
          <div class="navBtn" v-on:click="navigate(page)">{{ page }}</div>
        </div>
      </div>
      <div id="userInfo">
        {{ user.name + "," }}
        <span id="logout" v-on:click="logout">logout</span>
      </div>
    </div>
    <div id="leftCol">
      <div id="territories">
        <div
          v-for="block in sorted"
          :key="block.territoryName"
          class="terBlock"
        >
          <div
            v-for="ter in block"
            :key="ter.territoryNumber"
            v-on:click="changeSelected(ter.territoryNumber,ter)"
            :class="selectedClassTer(ter.territoryNumber)"
            class="ter"
          >
            <div
              class="terNumber"
              :class="selectedClassNum(ter.territoryNumber)"
            >
              {{ ter.territoryNumber }}
            </div>
          </div>
        </div>
        <div id="newTerBlock" class="terBlock">
          <div id="newTer" class="ter" v-on:click="newTer">
            <div class="terNumber">+</div>
          </div>
        </div>
        
      </div>
    </div>
    <div id="rightCol">
      <div v-if="loading" id="loadingPage">&lt;=click territory</div>
      <editPage v-else></editPage>
    </div>
  </div>
</template>

<script>
import editingPage from "./editingPage";
import { Territory } from "../../models/territory";
import store from "../../store";
export default {
  components: {
    editPage: editingPage,
  },
  data() {
    return {
      selected: "",
      loading: true,
      navList: ["home", "edit", "checkout", "admin"],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    sorted() {
      return this.$store.getters.sortedTerritories;
    },
  },
  methods: {
    changeSelected(num,ter) {
      var terID = ter.territoryID
      this.$data.selected = num;
      this.$store.dispatch("getTerritory", terID).then(() => {
        setTimeout(() => {
          this.$data.loading = false;
        }, 1000);
        //sets scroll to top
        var myDiv = document.getElementById("body");
        try {
          myDiv.scrollTop = 0;
        } catch (err) {
          null;
        }
      });
    },
    selectedClassTer(num) {
      if (num == this.$data.selected) {
        return "terS";
      }
    },
    selectedClassNum(num) {
      if (num == this.$data.selected) {
        return "terNumberS";
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    navigate(route) {
      this.$router.push("/" + route).catch(() => {});
    },
    newTer(){
      console.log('newTer!')
      var name = prompt('Enter Name of Territory i.e. East 12th & A')
      var number = prompt('Enter Territory Number')
      var type = prompt('Enter Territory Type (City or Business)')
      var ter = {
        territoryName:name,
        territoryNumber:number,
        territoryType:type
      }
      var nt = new Territory(ter)
      nt.addTer()
    },
  },
  beforeCreate: () => {
    store.dispatch("getTers");
  },
};
</script>

<style scoped>
@import "./css/edit.css";
</style>
