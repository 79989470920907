<template>
    <div id="AddressDoorStatusSelect">
        <div v-for='status in Object.keys(add.doorStatus)' :key="status.index">
            <input type="checkbox" v-model='add.doorStatus[status]' class='customCheck' @change='changed()'>
            <label for="checkbox" class="dPieceDataSelectOption">{{status}}</label>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            add: this.$store.getters.editAddress,
            oldAdd: {}
        }
    },
    methods: {
        changed(){
            this.add.updateAddr(this.oldAdd,'doorStatus')
        }
    },
    created(){
        this.oldAdd = JSON.parse(JSON.stringify(this.$store.getters.editAddress))
    },
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;1,400;1,500&display=swap');
    

    #AddressDoorStatusSelect{
        padding-left:15px;
        grid-column: 1/3;
    }
    .dPieceDataSelectOption{
        padding-left:10px;
        font-family: 'Arimo', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color:gray;
    }
    input[type='checkbox']{
        filter: grayscale(1);
    }

</style>