import Vue from "vue"
import Vuex from "vuex"
import { auth } from './db'
import { v4 as uuidv4 } from "uuid";
import { login, getFsUser, getTerritories, getSortedTer, getTerritoryContents} from './functions/dbFunctions'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        user: {},
        editingTerritory:{},
        Territories:[],
        edits: [],
        saving: false,
        loginError:'',
        editAddress:{},
    },
    getters:{
        user: state => { return state.user },
        isLoggedIn: state => { return state.user != {} ? true : false },
        territoriesLen: state => { return state.territories.length },
        sortedTerritories: state => { return getSortedTer(state.Territories) },
        editTerritory: state => { return state.editingTerritory },
        editAddress: state => { return state.editAddress },
        Territories: state => { return state.Territories },
    },
    mutations:{
        updateUser(state, user){
            state.user = user
        },
        // updateTerritories(state,ters){
        //     state.territories = ters
        // },
        updateEditTerritory(state,ter){
            state.editingTerritory = ter
        },
        updateTerritories(state,ters){
          state.Territories = ters
        },
        updateEditAddress(state,addr){
          state.editAddress = addr
        },
        updateEditAddressArray(state,payload){
          //console.log(payload)
          state.editAddress[payload.arrayType] = payload.array
        },
        addTempChild(state,payload){
          var child = payload.child
          var street = payload.street
          var et = state.editingTerritory
          var index = et.data.streets.findIndex(x => x.streetName === street)
          if(index === -1){
            var tempStreet = {
              addresses: [],
              streetName: street,
              uid: uuidv4()
            }
            state.editingTerritory.data.streets.push(tempStreet)
            index = et.data.streets.findIndex(x => x.streetName === street)
          }
          state.editingTerritory.data.streets[index].addresses.push(child)
        },
        removeAddr(state,payload){
          var street = payload.streetName
          var et = state.editingTerritory
          var index = et.data.streets.findIndex(x => x.streetName === street)
          var addrInET = et.data.streets[index].addresses.findIndex(x => x.houseNumber === payload.houseNumber)
          state.editingTerritory.data.streets[index].addresses.slice(addrInET,1)
        }
        
    },
    //dispatch
    actions:{
        login(context,payload){
          try{
            login(payload)
          } catch(err){
            console.log(err.message)
          }
        },
        logout(){
            auth.signOut()
        },

        async updateFsUser(context,user){
            if(user != undefined){
                await getFsUser(user).then( fsUser =>{
                context.commit('updateUser', fsUser )
                })
            } else {
                context.commit('updateUser',undefined)
            }
        },

        async getTers(context){
            await getTerritories().then(ters2 => { context.commit('updateTerritories',ters2)}
            )
        },
        async getTerritory(context,terID){
            await getTerritoryContents(terID)
            .then( response => {
                context.commit('updateEditTerritory', response)
            })
        },
        // async createChildAddress(context,payload){
        //   var Caddr = payload.ter.childAddress(payload.streetName)
        //   await Caddr.addAddr()
        //   this.dispatch('getTerritory', payload.ter.territoryID)
        // },
        async deleteAddr(context,addr){
          context.commit('removeAddr',addr)
          await addr.deleteAddr()
          this.dispatch('getTerritory', addr.territoryReference)
        }
    }
    
})



