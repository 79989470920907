<template>
    <div id="logObjectContainer">
        <div id="logSort" v-on:click="selectLogView" style="cursor:pointer;">SORT BY: {{this.reversed == false ? "OLDEST" : "NEWEST"}}</div>
        <div id="logsContainer" :key='this.compKey'>
            <div v-for="log in this.selectedLogs" :key="log.index">
                <log :logData='log'/>
            </div>
        </div>
    </div>
</template>

<script>
import log from './log.vue'
export default {
    name: 'logsObject',
    components:{
        log
    },
    data() {
        return {
            add: {},
            reversed: true,
            selectedLogs: [],
            logs: [],
            revLogs: [],
            compKey: 0,
        }
    },
    methods:{
        setData(){
            this.add = this.$store.getters.editAddress
            //this.logs = this.$store.getters.editAddress.addressLogs
            this.logs = JSON.parse(JSON.stringify(this.$store.getters.editAddress.addressLogs))
            this.revLogs = (JSON.parse(JSON.stringify(this.logs))).reverse()
            this.selectedLogs = this.revLogs

        },
        selectLogView(){
            this.reversed = !this.reversed
            if(this.reversed === false){
                this.selectedLogs = this.logs
            }else{
                this.selectedLogs = this.revLogs
            }
            this.compKey++
        }
    },
    created(){
        this.setData()
    },
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;1,400;1,500&display=swap');

    #logObjectContainer{
        width:auto;
        padding-top: px;
        display: grid;
        
    }
    #logSort{
        font-family: 'Arimo', sans-serif;
        font-size: 16px;
        font-weight: 400;
        padding: 8px 0px;
    }
    #logsContainer{
        max-height: 650px;
        overflow-y:scroll;
    }
</style>