<template>
    <div id="AddressLetterEditor" :key='this.componentKey'>
        <div v-for="(letter, index) in this.list" :key="letter.index">
            <div class="letterData">
                <input type="text" 
                    class="LetterTextField" 
                    :placeholder='placeHolder'
                    v-model="list[index]" 
                    @change='changed()' 
                    v-on:keyup.enter='changed'
                >
                <span 
                    class="deleteButton centerV" 
                    v-on:click='deleteItem(index)'
                    v-show='letter.length>0'
                >
                <img src="../../assets/Cross.svg">
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"addressListStatusEditor",
    data(){
        return{
            add: {},
            list: [],
            compList: [],
            componentKey: 0,
            listT: this.listType,
            placeHolder: this.placeHold,
        }
    },
    props: {
        listType:{
            type: String,
        },
        placeHold:{
            type: String,
        }
    },
    methods: {
        changed(){
            var workingList = JSON.parse(JSON.stringify(this.list));
            /\S/.test(workingList[workingList.length - 1]) === false ? workingList.splice(workingList.length-1,1) : true;
            var payload = {
                arrayType: this.listT,
                array: workingList
            }
            this.$store.commit('updateEditAddressArray',payload)
            this.$store.getters.editAddress.updateAddr(this.add,this.listT)
            this.setData()
            this.componentKey++
            this.$parent.updateIt()
        },
        deleteItem(index){
            this.list.splice(index,1)
            this.changed()
            this.componentKey++
        },
        setData(){
            this.add = JSON.parse(JSON.stringify(this.$store.getters.editAddress))
            this.list = JSON.parse(JSON.stringify(this.$store.getters.editAddress[this.listT]))
            this.compList = JSON.parse(JSON.stringify(this.$store.getters.editAddress[this.listT]))
        },
    },
    watch:{
        list: function(list){
            list.forEach((item,index)=>{
                //console.log("WATCHER:  item: " + item + " index:" + index + ' valid->' + this.valItem(item) + ' length: ' + item.length)
                if(item.length == 0 && index != list.length -1){
                    list.splice(index,1)
                    //console.log('item being cut: index-' + (index+1) + ' length-' + item.length )
                } else if (item.length > 0 && index == list.length -1){
                    list.push("")
                }
            })
            if(list.length == 0) list.push("")
        }
    },
    created(){
        this.setData()
    },
    destroyed(){
        this.compList = []
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;1,400;1,500&display=swap');
    
    #AddressLetterEditor{
        padding: 5px 0px 0px 15px;
        grid-column: 1/3;
    }
    .letterData{
        padding-bottom:5px;
        position: relative;
        border-left: 1px solid gray;
    }
    .LetterTextField{
        width:40%;
        
    }
    input[type='text']{
        font-family: 'Arimo', sans-serif;
        font-weight: 400;
        font-size: 16px;
        overflow: hidden;
        color:gray;
        border: 0px;
        border-radius: 3px;
        padding:1px 4px;
    }
    ::placeholder{
        font-style: italic;
        opacity: 1;
        color:lightgray;
    }
    .deleteButton{
        height: 21px;
        width: 21px;
        padding-bottom:9px;
        cursor: pointer;
    }
    img{
        filter: grayscale(1)
    }
    .centerV {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
</style>