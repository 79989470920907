import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/login'
import Home from '../components/home/home'
import Edit from '../components/edit/edit'
import Checkout from '../components/checkout/checkout'
//import store from '../store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: '/login',
            component: Login
        },
        {
            path: '/home',
            name: '/home',
            component: Home,
        },
        {
            path: '/edit',
            name: '/edit',
            component: Edit,
        },
        {
            path: '/checkout',
            name: '/checkout',
            component: Checkout,
            beforeEnter: (to, from, next) => {
              //get publishers (store.dispatch get publishers)
              next();
            }
        }
    ]
})

export default router