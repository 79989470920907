<template>
  <div id="container">
    <div id="header">
      <div id="title">terrdb</div>
      <div id="nav">
        <div v-for='page in navList' :key="page.index">
          <div class="navBtn" v-on:click='navigate(page)'>{{page}}</div>
        </div>
      </div>
      <div id="userInfo">
        {{user.name+','}}
        <span id='logout' v-on:click='logout'>logout</span>      
      </div>
    </div>
    <div id="leftCol">
      <p>hey, looks like a new website! <br> But it isn't! <br><br> There have been some major changes that took a while, but I think this 'version' will be the last major change.
      <br><br> Most of the changes (alot) are the ones you can't see because they aren't visual, but there are some new features aswell. <br><br>
      In order to edit just click the edit button in the Navigation cluster up top<br><br>Visually I think it looks better, but I'm not a User Inferface expert ( or in anything else lol)
      <br><br> you can add new addresses by hitting the plus symbol next to the street title; to delete, hit the 'X' left of an address
      <br><br> To add a street, scroll to the bottom of the list of streets and hit the obvious button; to delete a street, delete all the addresses in said street.
      <br><br> To add a Territory hit the Plus symbol at the bottom of the list of territories on the left. (probably should ask Micah if we need a whole new territory :P)
      <br><br> The territory editing load is slowing down, so I'll probably take over sooner or later unless y'all enjoy the work :D
      <br><br> 'Checkout' and 'Admin' are empty at the moment, probably just pipedreams but I might get around to it in the future.
      <br><br> if you have any suggestions tell me, seriously.
      <br><br> THANK YOU ALL for your help, it means a lot, when this is all over ill buy you guys breakfast or something
      <br><br> - Micah
      <br><br><br><br>
      <img src="https://media.giphy.com/media/MdLtzStFunTfVrhTqL/source.gif" alt="">
      </p>
    </div>
   </div>
</template>

<script>
export default {
  data() {
    return {
      navList: ['home','edit','checkout','admin'],
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
      logout(){
        this.$store.dispatch('logout')
      },
      navigate(route){
        this.$router.push('/'+route).catch(()=>{});
      }
  },
  
};
</script>

<style scoped>
@import "./css/home.css";
</style>
