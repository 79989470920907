<template>
  <div>
    <div id="body-container">
      <div id="checkoutHeader">
        <div id="terName">{{ territory != [] ? territory.terInfo.territoryName : ""}}</div>
      </div>
      <div id="body">
        <div id="actions">
          <div class="Btn" v-on:click="InOutOption()">{{status === 'Checked In'?'Check Out':'Check In'}}</div>
          <div class="Btn">Download</div>
          <div class="Btn">Send</div>
        </div>
        <div id="info">
          <div id="current">Current:<br>{{status}}</div>
          <div id="history">History:<br>{{status}}</div>
        </div>
      </div>
    </div>
    <CheckoutModal/>
  </div>
</template>

<script>
import CheckoutModal from "./modals/checkoutModal";
export default {
  components:{
    CheckoutModal
  },
  data() {
    return {};
  },
  computed: {
    territory() {
      var ter = this.$store.getters.editTerritory;
      if(this.lodash.isEmpty(ter)){
        console.log("empty")
        return []
      } else {
        return ter
      }
    },
    status(){
      if (this.territory.terInfo.checkoutLogs[0].status === 'checkedIn'){
        return 'Checked In'
      } else {
        return 'Checked Out'
      }
    }
  },
  methods: {
    InOutOption(){
      if(this.status === 'Checked In'){
        this.showCheckoutModal()
      } else {
        this.CheckIn()
      }
    },
    CheckIn(){
      console.log('checked in')
    },
    showCheckoutModal(){
      this.$modal.show('checkoutModal');
    }
  }

};
</script>
<style scoped>
@import "./css/checkoutPage.css";
</style>
