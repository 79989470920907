import Vue from "vue"
import App from "./App.vue"
import store from './store'
import router from './routes/'
import { auth } from './db'
import VModal from 'vue-js-modal'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueTheMask from 'vue-the-mask'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueTheMask)
Vue.use(VModal)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'))
Vue.config.productionTip = false;

//Vue.config.silent = true

new Vue({
    el: '#app',
    store,
    router,
    render: (h) => h(App),
    computed:{
        fsuser(){ return this.$store.getters.user}
    },
    created: function(){
        auth.onAuthStateChanged(async (user) => {
            await this.$store.dispatch('updateFsUser',user)
            if(this.fsuser == undefined){
                console.log('user is logged out')
                router.push('/login').catch(()=>{});
            } else if(!this.fsuser.approved){
                console.log('user is loggedIn - not approved ')
                router.push('/login').catch(()=>{});
            } else {
                console.log('user is logged in and approved')
                router.push('/home').catch(()=>{});
            }
            
        })
    },
}).$mount('#app')