<template>
  <div>
    <modal name='checkoutModal'
    :width="250"
    height="auto"
    :draggable="true"
    :focus-trap="true"
    @before-open='beforeOpen' 
    @before-close='beforeClose'>
    <div id="modal-container">
      <div id="modal-title">Check Out</div>
      <input type="text" id="nameInput" placeholder="Name"> 
      <input type="email" id="emailInput" placeholder="Email">
      <div id="checkoutBtn" class='Btn'>Checkout</div>
      <div id="checkout&SendBtn" class='Btn'>Checkout & Send</div>
      <div id="cancelBtn" class="Btn">Cancel</div>
    </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'checkoutModal',
  data(){
    return {
      form: {
        email: '',
        name: '',
      }
    }
  },
  methods:{
    beforeOpen(){
      console.log('opened Modal')
    },
    beforeClose(){
      this.form.email = ''
      this.form.name = ''
      console.log('closing Modal')
    }
  }
}
</script>
<style scoped>
#modal-title{
  padding-bottom:10px;
}
#nameInput{
  margin-bottom:10px;
}
#emailInput{
  margin-bottom:10px;
}
.Btn{
  border:1px solid black;
  width: 120px;
  padding:.25vh;
  margin-bottom:10px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
</style>